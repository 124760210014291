@import url("../../../../../static/ProximaNova/stylesheet.css");

.icardWrapper {
  background: url("../../../../../static/images/iCardBackground.png");
  width: 34.375em;
  height: 54.375em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.icardBackWrapper {
  background: url("../../../../../static/images/icard-back.png");
  width: 34.375em;
  height: 54.375em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10em;
  padding-left: 9.0625em;
  padding-right: 2.8125em;
}

.icardImage {
  width: 11.375em;
  height: 14.8125em;
  margin-top: 9em;
  margin-left: 5.4375em;
  border-radius: 0.25em;
  padding: 0.375em;
  border: 0.0625em solid #323234;
  box-sizing: border-box;
  border-radius: 0.375em;
  background-color: white;
}

.ICardContent {
  margin-left: 9.0625em;
  display: block;
  margin-right: 2.875em;
}

.ICardName {
  font-size: 2em;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #323234;
  margin-top: 1em;
  text-align: center;
  font-family: "Proxima Nova Bold";
  margin-bottom: 0.375em;
}

.ICardBranch {
  font-weight: 300;
  font-size: 1.375em;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #323234;
  text-align: center;
  margin-bottom: 2.125em;
  font-family: "Proxima Nova Rg";
}

.icardTitle {
  font-size: 1.125em;
  letter-spacing: 0.03em;
  color: #323234;
  font-family: "Proxima Nova Bold";
}

.icardText {
  font-size: 1.375em;
  letter-spacing: 0.04em;
  color: #323234;
  font-family: "Proxima Nova Lt";
}

.doswSign {
  width: 8.25em;
  height: auto;
  margin-left: 3.25em;
}

.studentSign {
  font-weight: bold;
  font-size: 1.125em;
  text-align: center;
  letter-spacing: 0.03em;
  font-family: "Proxima Nova Bold";
  color: #323234;

  padding-top: 0.3125em;
  padding-left: 0;
  padding-right: 0;
  border-top: 0.0375em solid #323234;
}

.studentSignImage {
  width: 7.5em;
}

.SignWrapper {
  text-align: center;
}

.icardQR {
  border: 0.0625em solid #000000;
  box-sizing: border-box;
  background: white;
  padding: 0.375em;
  margin-top: 10.8125em;

  margin-left: 3.375em;
}

.validity {
  font-size: 1.125em;
  font-family: "Proxima Nova Bold";
  margin-left: 3.375em;
}

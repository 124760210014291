@import "../../../../../style/index.scss";

.listHeading {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.375rem;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 3.75rem 0;
}

.container {
  min-height: 89vh;
  text-align: center;
}

.text {
  font-size: 1.25rem;
  line-height: 156%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #9c9dab;
}

.downloadCSVLink {
  color: black;
}

.uploadButton {
  border: 0.0938rem solid #1c84ee;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #1c84ee;
  background: none;
  padding: 0.75rem 1rem;

  outline: none;
  cursor: pointer;
}

.listUploadPreview {
  background: #f0f5f8;
  min-height: 89.5vh;
  padding: 5rem 7.5rem;
}

.listUploadPreviewMain {
  background: #ffffff;
  border-radius: 0.75rem;
  padding: 1.5rem 2.25rem;
}

.listUploadRow {
  padding-bottom: 2.25rem;
  border-bottom: 2px solid #ececec;
}

.listUploadTitle {
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3.0625rem;
  color: #2f444f;
}

.listUploadDelete {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #e06970;
}

.listUploadButton {
  background: #1c84ee;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.label {
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #2f444f;
  display: block;
  margin-bottom: 0.625rem;
}

.input {
  border: 0.0625rem solid #a9b9d0;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #2f444f;
  padding: 0.875rem 1rem;
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 1.25rem;
}

.listMain {
  padding: 2rem 11.25rem;
}

.listTilte {
  display: grid;
  grid-template-columns: 50% 30% 30%;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #878da6;
  background: #f0f5f8;
  padding: 1.25rem 1.5rem;
  border-radius: 0.0625rem;
}

.listData {
  display: grid;
  grid-template-columns: 50% 30% 30%;
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 1.9375rem;
  color: #2f444f;

  border-bottom: 1px solid #ececec;
  padding: 1rem;
}

.listTitle {
  margin-bottom: 3.25rem;
}

.downloadExcel {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  margin-right: 2.5rem;
  color: #878da6;
}

.listNavBarTitle {
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.375rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #2f444f;
}

.select {
  margin-bottom: 1.5rem;
}

.notifyTitle {
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 156%;
  letter-spacing: 0.01em;
  color: #2f444f;

  margin-bottom: 2rem;
}

.notifyImpText {
  font-size: 16px;
  line-height: 160%;
  color: #2f444f;
}

.notifyContent {
  color: #f36060;
}

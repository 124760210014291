.navbar {
  padding: 1.5rem 7.5rem;
  border: 0.0625rem solid #ececec;

  .link {
    font-size: 1.25rem;
    color: #2f444f;
    margin-right: 3.5rem;
    padding-bottom: 1.625rem;
  }

  .linkActive {
    border-bottom: 0.25rem solid #1c84ee;
  }
}


.dropdownOverlay{
  width: 10%;
  padding-top: 0.5em;
}

.navUserInfo{
  cursor: pointer;
}
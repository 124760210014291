.wrapperLogoTitleSubtitle {
  margin-top: -5rem;
}

.headingTag {
  margin-top: 8rem;
}

.logo {
  height: 11.063rem;
  width: 11.063rem;
}


.title {
  width: 26.875rem;
  height: 3.75rem;
  margin-left: -0.8rem;
  margin-top: 1.5rem;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.75rem;
  color: #2f444f;
}

.subTitle {
  width: 36.75rem;
  height: 4.188rem;
  margin-left: -6rem;
  margin-top: 0.5rem;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  color: #878da6;
}

.institute {
  margin-top: -15rem;
  margin-right: -63rem;
}

.footer {
  width: 400px;
  height: 19.34px;
  margin-top: 3rem;
  margin-bottom: -14rem;
  margin-left: 2.5rem;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.036rem;
  line-height: 1.438rem;
  display: flex;
  align-items: center;

  color: #5d6d76;
}

@import url("../../../../../static/ProximaNova/stylesheet.css");

.container {
  display: flex;
}

.emedicalWrapper {
  overflow-y: auto;
  margin: 20px;
  width: 52.5vw;
  height: 40vw;
  border: 2px solid grey;
  background-color: grey;
}

.guidelines {
  padding: 20px;
}

.titleRow {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
}

.instructionList {
  list-style-type: disc;
}

.instructionListItem {
  padding: 0.5em 0;
}

.coverPage {
  background: url("../../../../../static/images/eMedical/eMedicalBackgroundFront.png");
  padding: 20% 16%;
}

.listPage {
  background: url("../../../../../static/images/eMedical/eMedicalBackgroundBack.png");
  padding-left: 16%;
  padding-right: 2vw;
}

.coverPage,
.listPage {
  margin: 1vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 40vw;
  height: 56.605vw;
  background-size: cover;
}

.coverPage {
  display: flex;
  flex-flow: column nowrap;
}

.userData {
  padding: 2vw 14vw 1vw 0;

  &Name {
    font: {
      weight: bold;
      size: 12px;
    }
  }

  &Detail {
    font: {
      size: 12px;
    }
  }
}

.emedicalImage {
  width: 30%;
  height: auto;
  padding: 3px;
  border: 1px solid black;
  border-radius: 4px;
}

.emedicalTitle {
  font-size: 1.125em;
  letter-spacing: 0.03em;
  color: #323234;
  font-family: "Proxima Nova Bold";
}

.emedicalImageRowWrapper{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.emedicalTextRowWrapper {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
}

.emedicalTextWrapper {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.emedicalValue {
  font-size: 1em;
  letter-spacing: 0.04em;
  color: #323234;
  font-family: "Proxima Nova Lt";
}

.emedicalContentWrapper {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5vw;
  justify-content: space-between;
}

.emedicalDate {
  width: 15%;
  padding: 1.5em 0;
  text-align: center;
  vertical-align: top;
}

.emedicalPurpose {
  width: 20%;
  padding: 1.5em 0;
  text-align: center;
  vertical-align: top;
}

.emedicalDoctor {
  width: 15%;
  padding: 1.5em 0;
  text-align: center;
  vertical-align: top;
}

.emedicalPrescription {
  width: 20%;
  padding: 1.5em 0;
  text-align: center;
  vertical-align: top;
}

.emedicalAppointment {
  width: 25%;
  padding: 1.5em 0;
  text-align: center;
  vertical-align: top;
}

.emedicalListHeadingContainer {
  font: {
    size: 1em;
  }
}

.emedicalListContainer {
  font: {
    size: 0.8em;
  }
  border-bottom: 1px solid grey;

  .emedicalDate {
    color: #2980b9;
  }
}

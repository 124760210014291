.wrapper {
  padding: 56px 11.25rem ;
}

.titleRow {
  background: #f0f5f8;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #878da6;
  padding: 1.25rem 1.5rem;
}

.dataRow {
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 1.9375rem;
  color: #2f444f;
  padding: 2.5rem 1.25rem;
}

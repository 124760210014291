.wrapper {
  background: #f0f5f8;
  padding: 5rem 7.5rem;
  min-height: 100vh;
}

.contentWrapper {
  background: #ffffff;
  border-radius: 0.75rem;
  padding: 2.25rem 3.5rem;
}

.title {
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3.0625rem;
  color: #2f444f;
}

.membersDetails {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  color: #878da6;
  margin-top: 4px;
}

.certificateDate {
  vertical-align: middle;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  color: #878da6;
}

.approveButton {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #ffffff;
  background: #1c84ee;
  border-radius: 4px;
  border: none;
  padding: 1rem 1.5rem;
  outline: none;
  cursor: pointer;
}

.rejectButton {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #e06970;
  border: none;
  background: none;
  margin-right: 2.5rem;

  outline: none;
  cursor: pointer;
}

.dataTable {
  margin-top: 3.75rem;
}

.previewCertificste {
  font-weight: 600;
  font-size: 1rem;
  color: #1c84ee;
  padding: 0.8125rem 1rem;

  border: 0.0938rem solid #1c84ee;
  box-sizing: border-box;
  border-radius: 0.25rem;

  margin-top: 2rem;
  cursor: pointer;
}

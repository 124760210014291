.wrapper {
  margin: 3.5rem 11.25rem 3.5rem 11.25rem;
  border-bottom: 2px solid #ececec;
  padding-bottom: 4rem;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  color: #2f444f;
}

.buttongrid {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.titleRow {
  display: flex;
  vertical-align: middle;
}

.text {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #878da6;
  margin-top: 1.5rem;
  li {
    list-style-type: disc;
    margin-bottom: 0.6875rem;
    margin-left: 1.5rem;
  }
}

.QuiresList {
  display: block;
  margin-top: 2em;
}

.dataRow {
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 1.25em 1.25rem;
}

.dataCell {
  display: flex;
  justify-content: center;
}

.viewRemarkButton {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #1c84ee;
  cursor: pointer;
}

.QuiresDesc {
  padding-right: 2%;
}

.icard {
  margin-top: 2.0625rem;
  overflow-x: auto;
}

.wrapper {
  background: #f9f9fb;
  min-height: 100vh;
  padding: 5.375rem 11rem 5.375rem 11rem;
}

.form {
  background: #ffffff;
  padding: 2rem 4rem;
  border-radius: 0.75rem;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  color: #2f444f;
}

.helperText{
    font-size: 0.9rem;
    color: #878da6;
    margin-top: 1.5rem;
    li {
      margin-bottom: 0.6875rem;
      margin-left: 1.5rem;
    } 
}

.text {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: 1.5rem;
  li {
    margin-bottom: 0.6875rem;
    margin-left: 1.5rem;
  }
}

.wrapper {
  margin: 3rem 28.125rem;
  border: 0.0625rem solid #a9b9d0;
  box-sizing: border-box;
  border-radius: 0.75rem;
}

.step3Wrapper {
  margin: 3rem 22.75rem;
  border: 0.0625rem solid #a9b9d0;
  box-sizing: border-box;
  border-radius: 0.75rem;
}
.main {
  padding: 0 2.5rem;
}

.step {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: #1c84ee;
  margin: 2.25rem 0 0 2.5rem;
}

.title {
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 1rem;
}

.description {
  font-size: 1.25rem;
  color: #878da6;
  margin-bottom: 2.75rem;
}

.radios {
  display: block;
  font-size: 1.25rem;
  letter-spacing: 0.01em;
  color: #2f444f;
  margin-bottom: 2.25rem;
  display: flex;
  align-items: center;
}

.radioGroup {
  margin-left: 2.5rem;
  margin-bottom: 2rem;
}

.footer {
  border-top: 1px solid #a9b9d0;
  padding: 2rem 2.5rem;
}

.back {
  font-weight: 600;
  font-size: 1rem;
  color: #1c84ee;

  padding: 1rem 1.5rem;
  background: none;
  border: 1.5px solid #1c84ee;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.continue,
.continue:hover {
  background: #1c84ee;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;

  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.drop {
  background: #fbfdff;
  border: 1px dashed #a9b9d0;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 29.3125rem;
  margin: auto;
  padding: 4.75rem 8.1875rem;
  margin-bottom: 8.125rem;
  outline: none;
  img {
    width: 100%;
  }
}

.certiEditor {
  background: #ececec;
  border-radius: 0.25rem;
  margin-top: -1.875rem;
  margin-bottom: 3.25rem;
}

.sidepanel {
  background: #ffffff;
  border: 1px solid #a9b9d0;
  box-sizing: border-box;
  border-radius: 0rem 0rem 0rem 0.25rem;
  padding: 0 1.1875rem;
  // padding-bottom: 1.1875rem;
  padding-bottom: 15.25rem;
}

.sidepanelLabel {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: #000000;
  display: block;

  margin-top: 1.5rem;
  margin-bottom: 0.375rem;
}

.colorField {
  border: 1px solid #b8cadd;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  display: block;
  outline: none;

  font-size: 12px;
  letter-spacing: 0.01em;
  color: #000000;
}

.upload_button {
  font-weight: 600;
  font-size: 0.75rem;
  color: #1c84ee;
  padding: 0.5rem auto;
  text-align: center;
  border: 1px solid #1c84ee;
  box-sizing: border-box;
  border-radius: 2px;
  background: none;
  width: 100%;
}

.ceritPreview {
  padding: 1.1875rem 1.75rem;
  overflow: auto;
}

.uploadedSidebarFiles {
  background: #f2f7fa;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  display: block;
  letter-spacing: 0.01em;
  color: #000000;
  padding: 8px;
}

.removeUploadedFiles {
  font-weight: 600;
  font-size: 0.6875rem;
  letter-spacing: 0.01em;
  color: #878da6;
  margin-top: 0.375rem;
  cursor: pointer;
}

.sheetLink {
  font-weight: 600;
  font-size: 1rem;
  color: #878da6;

  float: right;
  margin-top: -4.2rem;
}

.colorUpload {
  width: 100%;
  border: 1px solid #b8cadd;
  box-sizing: border-box;
  border-radius: 0.125rem;
  outline: none;
  padding: 0.1875rem 0.3125rem;

  font-size: 0.75rem;
  letter-spacing: 0.01em;
  color: #000000;
}

.wrapper {
  padding: 0rem 11.25rem;
}

.nav {
  margin-top: 2rem;
  margin-bottom: 2.8125rem;

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #2f444f;
    display: inline-block;
    margin-right: 2.5rem;
    margin-bottom: 0.25rem;
  }
}

.active {
  border-bottom: 2px solid #1c84ee;
}
.titleRow {
  background: #f0f5f8;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #878da6;
  padding: 1.25rem 1.5rem;
}

.dataRow {
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 1.9375rem;
  color: #2f444f;
  padding: 2.5rem 1.25rem;
}

.rejectButton {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #e06970;
  border: none;
  background: none;
  display: inline;
  cursor: pointer;
  margin-left: 2rem;
  padding: 0.8rem 1.5rem;
  outline: none;
}

.viewMore {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  // text-decoration-line: underline;
  color: #1c84ee;
  margin-top: 0.8rem;
  float: right;
}

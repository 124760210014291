.main {
  border: 0.125rem solid #1c84ee;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
}

.title {
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 156%;
  letter-spacing: 0.01em;
  color: #000000;
}

.image {
  width: 220%;
  // height: 37.9375rem;

}

.wrapper {
  display: grid;
  grid-template-columns: 35% 55%;
  grid-column-gap: 10%;

  margin: 7.5rem 11.25rem;
}

.imgWrapper{
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #878da6;
  height: 38.9375rem;
  box-sizing: border-box;
}
